import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ProjectsPage = () => {
  const data = useStaticQuery(
    graphql`
      {
        contentfulProjectsPage {
          allProjects {
            title
            slug
            directedBy
            writtenBy
            starring
            trailer {
              file {
                url
              }
            }
          }
        }
      }
    `
  );

  const projects = data.contentfulProjectsPage.allProjects;

  return(
    <Layout>
    <SEO title="Projects" description="Championing female and emerging voices and stories."/>

      <section id="featured" className="bg-light-brown position-relative">
        {
          projects.map((project, index) => (
            <Link className="project" to={`/${project.slug}`} key={`project-${index}`}>
              <div className="vw-100 vh-100 py-5 position-relative">
                <div className="h-100">
                  <div className="feature-video d-flex align-items-center justify-content-center">
                    <video className="project-video" autoPlay={ true } playsInline muted loop controls={ false }>
                      <source src={ `https:${project.trailer.file.url}` } type="video/mp4" />
                    </video>
                    <div className="feature-video-overlay"/>
                  </div>
                  
                  <Container className="h-100">
                    <div className="h-100 d-flex flex-column justify-content-end justify-content-lg-end">
                      <Row className="mb-lg-4">
                        <Col lg={{ span: 5 }} className="pr-lg-0">
                          <div className="feature-title color-pink">
                            <h2 className="mb-3">{ project.title }</h2>
                          </div>
                        </Col>
                        <Col lg={{ span: 7 }} className="pl-lg-5">
                          <Row>
                            <Col lg={{ span: 3 }} className="mt-lg-3 mt-xl-4 pr-lg-0">
                              <div className="details color-pink mb-4 mb-lg-0">
                                <p className="mb-0">DIRECTED BY</p>
                                <p className="mb-0">{ project.directedBy }</p>
                              </div>
                            </Col>
                            <Col lg={{ span: 3 }} className="mt-lg-3 mt-xl-4 pr-lg-0">
                              <div className="details color-pink mb-4 mb-lg-0">
                                <p className="mb-0">WRITTEN BY</p>
                                <p className="mb-0">{ project.writtenBy }</p>
                              </div>
                            </Col>
                            <Col lg={{ span: 6 }} className="mt-lg-3 mt-xl-4 pr-lg-0">
                              <div className="details color-pink mb-4 mb-lg-0">
                                <p className="mb-0">STARRING</p>
                                <p className="mb-0">{ project.starring }</p>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Container>
                </div>
              </div>
            </Link>
          ))
        }
      </section>
    </Layout>
  )
}

export default ProjectsPage
